<template>
  <div>
    <h3>Your order is paid for, now we just need a few more details.</h3>
    <p>To help establish your company registration, we need to gather a few more pieces of information about the business registration you need completed.</p>
    <p>Thanks for using our services!</p>
    <b-button
      class="float-right"
      variant="primary"
      @click="next"
    >
      Next
    </b-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash/lang'
import { identity } from 'lodash/util'
import { sortBy } from 'lodash/collection'

export default {
  name: 'PostPayment',

  props: {
    companyId: String,
    invoiceId: String,
  },

  computed: {
    ...mapGetters('checkout', ['cartItems', 'findProduct']),
    ...mapGetters('invoices', ['find']),
    ...mapGetters('stageline', ['isStagelineRoute']),

    invoice() {
      return this.find(this.invoiceId)
    },

    orderItems() {
      return this.invoice.order.order_items
    },

    orderedProducts() {
      return this.orderItems.filter(item => {
        return item.product.kind === 'filing_product' &&
          item.status === 'awaiting-client-input' &&
          item.product.id !== this.productId && isEmpty(item.data)
      }).map(item => item.product)
    },

    nextProduct() {
      return sortBy(this.orderedProducts, 'name')
        .filter(identity)[0]
    },

    nextFilingMethodIds() {
      const product = this.nextProduct

      return this
        .orderItems
        .filter(item => item.product.id === product.id)
        .map(item => item.product.filing_method.id)
    },
  },

  methods: {
    ...mapActions('invoices', ['loadIfNotAvailableById']),
    next() {
      this.$router.push({
        name: 'checkout-required-info',
        params: {
          companyId: this.companyId,
          invoiceId: this.invoiceId,
          productId: this.nextProduct.id,
        },
        query: { ids: this.nextFilingMethodIds },
      })
    },
  },

  async mounted() {
    this.$emit('toggleButtonVisibility', { backButtonHidden: false, addContactButtonHidden: true })
    await this.loadIfNotAvailableById({ ids: this.invoiceId, params: { include_details: true } })
    this.$bvToast.toast('Paid Successfully', {
      title: 'Complete',
      variant: 'success',
      solid: true,
      autoHideDelay: 15000,
    })
  },
}
</script>

<style lang="scss" scoped>
</style>
